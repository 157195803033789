


































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  ICourierDedicatedParams,
  IDataCell,
  IHeaderCell,
  IOptions,
  sortOptions,
} from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/ManageCourierDedicatedController'
import {
  CourierDedicatedArea,
  CourierDedicatedListItem,
} from '@/domain/entities/ManageCourierDedicated'
import { Location } from 'vue-router'

@Component({
  components: {
    Button,
    TextInput,
    DataTableV2,
    PaginationNav,
    DropdownSelect,
    LoadingOverlay,
  },
})
export default class CourierDedicatedListPage extends Vue {
  controller = controller
  sortOptions: Array<IOptions> = sortOptions

  parameters: ICourierDedicatedParams = {
    page: 1,
    per_page: 10,
    sort: this.sortOptions[0],
    keyword: '',
  }

  headers: Array<string | IHeaderCell> = [
    this.headerCellMapper('No.', '50px'),
    this.headerCellMapper('Nama Kurir', '220px'),
    this.headerCellMapper('Area Dedicated MP', '240px'),
    this.headerCellMapper('Area Dedicated CA', '240px'),
    this.headerCellMapper('Area Dedicated Client', '240px'),
    this.headerCellMapper('Aksi', '200px'),
  ]

  courierDedicatedData: Array<Array<string | Array<string> | IDataCell>> = []

  created(): void {
    this.fetchCourierDedicated()
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      keyword: this.parameters.keyword,
      sort_by: <string>this.parameters.sort.value,
    }
  }

  private fetchCourierDedicated(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getAllCourierDedicated(this.params)
  }

  private routeToDetail(data: string): Location {
    return {
      name: 'CourierDedicatedDetailPage',
      params: {
        courier: data,
      },
    }
  }

  private onSearchFilter(): void {
    if (
      (this.parameters.keyword && this.parameters.keyword.length > 2) ||
      !this.parameters.keyword
    ) {
      this.onFilterList()
    }
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchCourierDedicated(true)
  }, 500)

  private tableCellMapper(
    value: string | number | boolean,
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private formatAreaNames(list?: Array<CourierDedicatedArea>): string {
    if (list?.length) {
      const suffix = list.length > 3 ? ',...' : ''
      let names: Array<string> = []

      list.slice(0, 3).forEach(area => {
        if (area.areaName) {
          names.push(area.areaName)
        }
      })

      return names.join(', ') + suffix
    }

    return '-'
  }

  @Watch('controller.dataCourierDedicatedList')
  setCourierDedicatedData(data: CourierDedicatedListItem[]): void {
    this.courierDedicatedData = data.map((item, index) => {
      const areaMP = this.formatAreaNames(item.areaDedicatedMP)
      const areaCA = this.formatAreaNames(item.areaDedicatedCA)
      const areaClient = this.formatAreaNames(item.areaDedicatedClient)

      return [
        this.tableCellMapper(
          (this.parameters.page - 1) * this.parameters.per_page + index + 1,
          '50px'
        ),
         this.tableCellMapper(`[${item.courierId}] ${item.courierName} (${item.courierType}) ${item.courierPhone}`, '220px'),
        this.tableCellMapper(areaMP, '240px'),
        this.tableCellMapper(areaCA, '240px'),
        this.tableCellMapper(areaClient, '240px'),
        this.tableCellMapper(
          `${String(item.courierId)}-${item.courierName || ''}`,
          '200px'
        ),
      ]
    })
  }
}
