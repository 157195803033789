function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-end gap-2 my-6"},[_c('div',{staticClass:"w-full max-w-140 flex flex-col gap-2"},[_c('span',{staticClass:"filter-label"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"data-testid":"courier-dedicated__search-input","placeholder":"Cari dengan ID, Nama Kurir, Area","type":"search","customClass":"hide-button-clear"},on:{"input":_vm.onSearchFilter},model:{value:(_vm.parameters.keyword),callback:function ($$v) {_vm.$set(_vm.parameters, "keyword", $$v)},expression:"parameters.keyword"}})],1),_c('div',{staticClass:"w-40 flex flex-col gap-2"},[_c('span',{staticClass:"filter-label"},[_vm._v(" Urutkan ")]),_c('DropdownSelect',{attrs:{"data-testid":"courier-dedicated__sort-select","allowEmpty":false,"clearOnSelect":false,"searchable":false,"options":_vm.sortOptions},on:{"input":_vm.onFilterList},model:{value:(_vm.parameters.sort),callback:function ($$v) {_vm.$set(_vm.parameters, "sort", $$v)},expression:"parameters.sort"}})],1)]),_c('div',{staticClass:"w-full overflow-x-auto"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.courierDedicatedData},scopedSlots:_vm._u([{key:"1",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(props.data.value))])])]}},{key:"2",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"h-full mr-4"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(props.data.value || '-'))])])]}},{key:"3",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"h-full mr-4"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(props.data.value || '-'))])])]}},{key:"4",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"h-full mr-4"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(props.data.value || '-'))])])]}},{key:"5",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',[_c('Button',{staticClass:"w-32-a py-2 w-full text-xs",attrs:{"routeTo":_vm.routeToDetail(props.data.value)},on:{"click":function($event){_vm.$router.push(_vm.routeToDetail(props.data.value))}}},[_c('span',[_vm._v("Detail")])])],1)]}}])})],1),_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.per_page,"totalItem":_vm.controller.paginationDataCourierDedicated.totalItem},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        _vm.fetchCourierDedicated()
      })($event)},"input":function () {
        _vm.fetchCourierDedicated()
      }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }